import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, Modal, Form, Input, Typography, Card, message } from 'antd';
import 'antd/dist/reset.css'; // Ensure Ant Design styles are imported
import { fetchWithToken } from '../share/apiUtils';
import { backendUrl } from '../../constants';
import DoctorCreateModal from './DoctorCreateModal';

const { Title } = Typography;
const DoctorPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [doctor, setDoctor] = useState([]);
  const [currentDoctor, setCurrentDoctor] = useState(null);

  const fetchDoctor = useCallback( async () => {
      try {
        const response = await fetchWithToken(`${backendUrl}/doctors`, {
          method: 'GET',
        });
    
        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
        }
    
        const data = await response.json();
        setDoctor(data);
      } catch (error) {
        console.error('Error fetching options:', error);
        return [];
      }
    }, []);
  
    useEffect(() => {
      fetchDoctor('');
    }, [fetchDoctor]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Full name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone number',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Hospital',
      dataIndex: 'hospital_name',
      key: 'hospital_name',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="primary" onClick={() => {
          setCurrentDoctor(record);
          setOpenModal(true);
        }}>Edit</Button>
      ),
    }
  ];
  return (
    <div style={{width:'800px', height:'100vh'}}>
      <div>
        <Title level={4} style={{textAlign:'left'}}>Bác sĩ</Title>
      </div>
      <Card bordered={false} style={{padding:0, textAlign:'right'}} >
        <Button type="primary" onClick={()=>{
          setOpenModal(true);
        }} style={{margin:'20px'}}>
          Add Doctor
        </Button>
        <Table columns={columns} dataSource={doctor} />
      </Card>

      
      {openModal && <DoctorCreateModal 
        openModal={openModal} 
        onClose={()=>{
          setCurrentDoctor(null);
          setOpenModal(false);
        }}
        onCreateDoctor={() => {
          setCurrentDoctor(null);
          fetchDoctor();
        }}
        currentDoctor={currentDoctor}
      />}
    </div>
  );
};

export default DoctorPage;
