import React, { useState, useCallback, useEffect } from 'react';
import { Card, Form, Input, Button, Select, message, Typography, Space, DatePicker, Modal } from 'antd';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';

const { Option } = Select;
const { Title, Text } = Typography;

const SendZaloNotification = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [patients, setPatients] = useState([]);
  const [templateData, setTemplateData] = useState({});
  const [doctor, setDoctor] = useState(null);
  const [patient, setPatient] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [testPhoneNumber, setTestPhoneNumber] = useState('');
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const fetchDoctors = useCallback(async (keyword = '') => {
    try {
      const response = await fetchWithToken(`${backendUrl}/doctors?full_name=${encodeURIComponent(keyword)}`, {
        method: 'GET',
      });
      if (response.ok) {
        const data = await response.json();
        setDoctors(data);
      } else {
        throw new Error('Failed to fetch doctors');
      }
    } catch (error) {
      message.error('Error fetching doctors: ' + error.message);
    }
  }, []);

  const fetchPatients = useCallback(async (keyword = '') => {
    try {
      const response = await fetchWithToken(`${backendUrl}/patients?full_name=${encodeURIComponent(keyword)}`, {
        method: 'GET',
      });
      if (response.ok) {
        const data = await response.json();
        setPatients(data);
      } else {
        throw new Error('Failed to fetch patients');
      }
    } catch (error) {
      message.error('Error fetching patients: ' + error.message);
    }
  }, []);

  useEffect(() => {
    fetchDoctors();
    fetchPatients();
  }, [fetchDoctors, fetchPatients]);

  useEffect(() => {
    const template = templateOptions.find(t => t.id === templateId);
    const phone = template?.receiver === 'doctor' ? doctor?.phone_number : patient?.phone_number;

    if (template) {
      setTemplateData({
        ...templateData,
        doctor_name: doctor?.full_name,
        doctor: doctor?.full_name,
        patient_name: patient?.full_name,
        patient_id: patient?.id,
        customer_name: patient?.full_name,
        phone_number: phone,
        doctor_email: doctor?.email,
        patient_email: patient?.email
      });
    }
  }, [doctor, patient]);

  useEffect(() => {
    const template = templateOptions.find(t => t.id === templateId);
    const phone = template?.receiver === 'doctor' ? doctor?.phone_number : patient?.phone_number;

    if (template) {
      setTemplateData({
        doctor_name: doctor?.full_name,
        doctor: doctor?.full_name,
        patient_name: patient?.full_name,
        patient_id: patient?.id,
        customer_name: patient?.full_name,
        phone_number: phone,
        doctor_email: doctor?.email,
        patient_email: patient?.email
      });
    }
  }, [templateId]);

  useEffect(() => {
    form.setFieldsValue({ template_data: templateData });
  }, [templateData]);

  const templateOptions = [
    { id: 411157, receiver: 'patient', name: 'Thông báo bệnh nhân thanh toán', description: 'Thông báo yêu cầu thanh toán', params: ['phone_number', 'contract_number', 'transfer_amount', 'patient_name', 'product'] },
    { id: 411158, receiver: 'patient', name: 'Thông báo mẫu không đạt', description: 'Thông báo mẫu không đạt yêu cầu', params: ['phone_number', 'customer_name', 'order_code', 'doctor', 'test', 'reason'] },
    { id: 411159, receiver: 'patient', name: 'Thông báo cho bệnh nhân đã thanh toán', description: 'Xác nhận đã nhận thanh toán', params: ['phone_number', 'customer_name', 'order_code', 'doctor', 'test', 'transfer_amount', 'date'] },
    { id: 411160, receiver: 'doctor', name: 'Thông báo cho bác sĩ mẫu đang xử lý', description: 'Thông báo cho bác sĩ về mẫu xét nghiệm', params: ['phone_number', 'doctor_name', 'patient_id', 'patient_name', 'hospital', 'test', 'date'] },
    { id: 411161, receiver: 'patient', name: 'Thông báo hoàn tiền', description: 'Thông báo hoàn tiền cho khách hàng', params: ['phone_number', 'customer_name', 'order_code', 'hospital', 'doctor_name', 'account_number', 'amount', 'reason'] },
    { id: 411162, receiver: 'doctor', name: 'Thông báo kết quả cho bác sĩ', description: 'Thông báo kết quả cho bác sĩ', params: ['phone_number', 'doctor_name', 'patient_id', 'patient_name', 'hospital', 'test', 'doctor_email'] },
    { id: 411163, receiver: 'patient', name: 'Thông báo kết quả cho bệnh nhân', description: 'Thông báo kết quả cho bệnh nhân', params: ['phone_number', 'customer_name', 'order_code', 'patient_email', 'doctor_name', 'test_result', 'test_no_result'] }
  ];

  const sendNotification = useCallback(async (values) => {
    const currentTemplate = templateOptions.find(t => t.id === values.template_id);
    const recipientType = currentTemplate.receiver === 'doctor' ? 'bác sĩ' : 'bệnh nhân';
    const recipientName = recipientType === 'bác sĩ' ? doctor?.full_name : patient?.full_name;
    const phoneNumber = values.template_data.phone_number;
    const boldTemplateName = `<b>${currentTemplate.name}</b>`;
    const message = `Xác nhận gửi ${boldTemplateName} đến số điện thoại <b>${phoneNumber}</b> của <b>${recipientType}:</b> <b>${recipientName}</b>`;
    setConfirmMessage(message);
    setIsConfirmVisible(true);
  }, [doctor, patient, templateOptions]);

  const handleConfirmOk = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      const response = await fetchWithToken(`${backendUrl}/send_zalo_notification/`, {
        method: 'POST',
        body: JSON.stringify({
          phone_number: values.template_data.phone_number,
          template_id: values.template_id,
          template_data: values.template_data
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send notification');
      }

      message.success('Gửi thông báo thành công! / Notification sent successfully!');
      form.resetFields();
      setDoctor(null);
      setPatient(null);
      setTemplateId(null);
      setTemplateData({});
      setIsConfirmVisible(false);
    } catch (error) {
      message.error('Lỗi khi gửi thông báo / Error sending notification: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmCancel = () => {
    setIsConfirmVisible(false);
  };

  const handleTemplateChange = (value) => {
    setTemplateId(value);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const response = await fetchWithToken(`${backendUrl}/send_zalo_notification/`, {
        method: 'POST',
        body: JSON.stringify({
          phone_number: testPhoneNumber,
          template_id: templateId,
          template_data: templateData
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send test notification');
      }

      message.success('Test notification sent successfully!');
      form.resetFields();
      setDoctor(null);
      setPatient(null);
      setTemplateId(null);
      setTemplateData({});
      setIsModalVisible(false);
    } catch (error) {
      message.error('Error sending test notification: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const isFormValid = () => {
    const fieldsValue = form.getFieldsValue();
    const templateData = fieldsValue?.template_data;
    if (fieldsValue && templateData) {
      const hasErrors = Object.values(fieldsValue).some((value) => value === undefined || value === '');
      const hasErrorsTemplateData = Object.values(templateData).some((value) => value === undefined || value === '');
      return !hasErrors && !hasErrorsTemplateData;
    }
    return false;
  };

  return (
    <Card style={{ maxWidth: 800, margin: '24px auto', padding: '24px' }}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Title level={2}>Gửi thông báo Zalo / Send Zalo Notification</Title>
        
        <Form
          form={form}
          layout="vertical"
          onFinish={sendNotification}
        >
            <Form.Item
            name="template_id"
            label="Mẫu thông báo / Notification Template"
            rules={[{ required: true, message: 'Vui lòng chọn mẫu thông báo / Please select template' }]}
          >
            <Select placeholder="Chọn mẫu thông báo / Select notification template" onChange={handleTemplateChange}>
              {templateOptions.map(template => (
                <Option key={template.id} value={template.id}>
                  <div>
                    <Text strong>{template.name}</Text>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="doctor_id"
            label="Chọn bác sĩ / Select Doctor"
          >
            <Select
              showSearch
              placeholder="Chọn bác sĩ / Select doctor"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSearch={fetchDoctors}
              onChange={(value) => {
                setDoctor(doctors.find(doctor => doctor.id === value));
              }}
            >
              {doctors.map(doctor => (
                <Option key={doctor.id} value={doctor.id}>{`${doctor.full_name} - ${doctor.phone_number}`}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="patient_id"
            label="Chọn bệnh nhân / Select Patient"
          >
            <Select
              showSearch
              placeholder="Chọn bệnh nhân / Select patient"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSearch={fetchPatients}
              onChange={(value) => {
                setPatient(patients.find(patient => patient.id === value));
              }}
            >
              {patients.map(patient => (
                <Option key={patient.id} value={patient.id}>{`${patient.full_name} - ${patient.phone_number}`}</Option>
              ))}
            </Select>
          </Form.Item>

          {templateId && templateOptions.find(t => t.id === templateId).params.map(param => (
            <Form.Item
              key={`${param}-${templateId}`}
              name={['template_data', param]}
              label={param}
              rules={[{ required: true, message: `Vui lòng nhập ${param} / Please input ${param}` }]}
            >
              {param === 'date' ? (
                <DatePicker 
                style={{ width: '100%' }} 
                placeholder="Chọn ngày / Select date" 
                format="DD/MM/YYYY" 
                onChange={(date, dateString) => {
                  if (date) {
                    setTemplateData({
                      ...templateData,
                      [param]: date
                    });
                  }
                }}
              />
              ) : (
                <Input 
                  placeholder={`Nhập ${param} / Enter ${param}`} 
                  onChange={(e) => {
                    setTemplateData({
                      ...templateData,
                      [param]: e.target.value
                    });
                  }}
                />
              )}
            </Form.Item>
          ))}

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} disabled={!isFormValid()}>
              Gửi thông báo / Send Notification
            </Button>
            <Button type="primary" onClick={showModal} style={{ marginLeft: '8px' }} disabled={!isFormValid()}>
              Send Test
            </Button>
          </Form.Item>
        </Form>
        
        <Modal title="Send Test Notification" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} confirmLoading={loading}>
          <Input placeholder="Enter phone number" value={testPhoneNumber} onChange={(e) => setTestPhoneNumber(e.target.value)} />
        </Modal>

        <Modal title="Xác nhận gửi thông báo" open={isConfirmVisible} onOk={handleConfirmOk} onCancel={handleConfirmCancel} confirmLoading={loading}>
          <p dangerouslySetInnerHTML={{ __html: confirmMessage }} />
        </Modal>
      </Space>
    </Card>
  );
};

export default SendZaloNotification; 