import React, { useState,useCallback, useEffect } from 'react';
import { Table, Card, Button, Select, Input, Tag, Space, Modal, Typography, message } from 'antd';
import './DashboardPage.css';
import { PlusOutlined, ExportOutlined, EditOutlined, NotificationOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';
import ChangeStatusModal from './ChangeStatusModal';
import NotifyModal from './NotifyModal';
import ChangeResultModal from './ChangeResultModal';

const { Search } = Input;

const formatNumber = (number) => {
  const formatter = new Intl.NumberFormat('de-DE'); // German locale
  return formatter.format(number);
};
const statusMapping = {
  'NEW': 'Mới',
  'WAITING_FOR_PAYMENT': 'Mẫu đạt - Chờ thanh toán',
  'FAILED': 'Mẫu không đạt',
  'PROCESSING': 'Đã thanh toán - Đang xử lý',
  'REFUND': 'Hoàn trả',
  'DELAY': 'Delay',
  'COMPLETED': 'Đã hoàn thành',
  'CANCELLED': 'Huỷ',
};

const notifyStatus = [
  'FAILED',
  "WAITING_FOR_PAYMENT",
  "PROCESSING",
  'REFUND',
  "DELAY",
  "COMPLETED",
];

const TableContent = () => {
  const navigate = useNavigate();
  const [sourceData, setSourceData] = useState([]);
  const [status, setStatus] = useState('');
  const [partner, setPartnerCode] = useState('');
  const [textSearch, setTextSearch] = useState('');
  const [selectOrderId, setSelectOrderId] = useState('');
  const [isOpenStatusChangeModal, setIsOpenStatusChangeModal] = useState(false);
  const [isOpenNotifyModal, setIsOpenNotifyModal] = useState(false);
  const [isOpenChangeResultModal, setIsOpenChangeResultModal] = useState(false);
  const [curNotifyStatus, setCurNotifyStatus] = useState([]);
  const [curStatus, setCurStatus] = useState('')
  const [curProducts, setCurProducts] = useState([]);

  const fetchTests = useCallback( async (status, partner, text) => {
    try {
      const baseUrl = `${backendUrl}/get_orders`;
      const url = new URL(baseUrl);
      const params = new URLSearchParams();
      if (status) params.append('status', status);
      if (partner) params.append('partner', partner);
      if (text) params.append('text', text);
      url.search = params.toString();
      const response = await fetchWithToken(url.toString(), {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setSourceData(data);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  useEffect(() => {
    fetchTests(status, partner, textSearch);
  }, [textSearch, status, partner, fetchTests, isOpenStatusChangeModal, isOpenChangeResultModal]);

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1, // Incrementing index starting from 1
      key: 'number',
    },
    {
      title: 'Mã Đơn hàng',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: 260,
      render: (status, record) => {
        let diffDays = undefined
        let result_expect_date = undefined
        if (status === 'PROCESSING' || status === 'DELAY'){
          const products = record.products
          if (record.products.length && record.products.length > 0) {
            result_expect_date = products.reduce((min, p) => p.result_expect_date < min ? p.result_expect_date : min, products[0].result_expect_date);
          }else{
            result_expect_date = record.result_expect_date;
          }
          const date = new Date(result_expect_date);
          const currentDate = new Date();
          const diffTime = date - currentDate;
          diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays <= 3) {
            status = 'DELAY'
          }
        }
        let curType = 'default';
        if (status === 'COMPLETED' || status === 'REFUND') {
          curType = 'success';
        } else if (status === 'DELAY' || status === 'FAILED') {
          curType = 'error';
        } else if (status === 'PROCESSING' || status === 'WAITING_FOR_PAYMENT') {
          curType = 'warning';
        }
  
        return <>
          <Tag color={curType}> {statusMapping[status]?.toUpperCase()}</Tag>
          {
            status !== 'COMPLETED' && status !== 'DELAY' && 
              <EditOutlined style={{ color: 'green' }} onClick={() => {
                setSelectOrderId(record.id);
                setCurStatus(status)
                setIsOpenStatusChangeModal(true);
              }} />
          }
          
          {status === 'FAILED' && <div style={{paddingTop:'5px'}}>Lý do: {record.failed_reason}</div>}
          {((status === 'PROCESSING' || status === 'DELAY') && result_expect_date) && <div style={{paddingTop:'5px'}}>Ngày dự kiến: {result_expect_date}</div>}
          {(diffDays!== undefined && (status === 'PROCESSING' || status === 'DELAY')) && <div style={{paddingTop:'5px'}}>Ngày còn lại: {diffDays} ngày</div>}
        </>;
      }
    },
    {
      title: 'Đối tác',
      dataIndex: 'partner_code',
      key: 'partner_code',
      render: (partner_code) => {
        if (partner_code === 'Taman') {
          return 'Tâm An';
        } else {
          return 'Client';
      }},
    },
    {
      title: 'Bác sĩ chỉ định',
      render: (text, record) => record.doctor_id.full_name,
      key: 'name_doctor',
      width: 200,
    },
    {
      title: 'Tên bệnh nhân',
      render: (text, record) => record.patient_id.full_name,
      key: 'name_patient',
      width: 200,
    },
    {
      title: 'Thành tiền (VNĐ)',
      render: (text, record) => formatNumber(record.total_amount) + ' VND',
      key: 'final_price',
    },
    {
      title: 'Điểm lấy mẫu',
      dataIndex: 'sampling_facility',
      key: 'sampling_address',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => {
        let statusAction = record.status;
        let diffDays = undefined
        if (record.status === 'PROCESSING' || record.status === 'DELAY'){
          const products = record.products
          let result_expect_date;
          if (record.products.length && record.products.length > 0) {
            result_expect_date = products.reduce((min, p) => p.result_expect_date < min ? p.result_expect_date : min, products[0].result_expect_date);
          }else{
            result_expect_date = record.result_expect_date;
          }
          const date = new Date(result_expect_date);
          const currentDate = new Date();
          const diffTime = date - currentDate;
          diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays <= 3) {
            statusAction = 'DELAY'
          }
        }

        return (
          <Space size="middle">
            <EditOutlined style={{ color: 'green' }} onClick={() => {
              navigate('/edit-test?id=' + record.id);
            }} />
            {notifyStatus.includes(statusAction) && (
              <NotificationOutlined 
                style={{ color: 'green' }} 
                onClick={() => {
                  setCurNotifyStatus(statusAction);
                  setSelectOrderId(record.id);
                  setIsOpenNotifyModal(true);
                }}
              />
            )}
            {
              (statusAction === 'PROCESSING' || statusAction === 'DELAY') && (
                <CheckCircleOutlined 
                  style={{ color: 'green' }} 
                  onClick={() => {
                    setSelectOrderId(record.id);
                    setIsOpenChangeResultModal(true);
                    setCurProducts(record.products);
                  }}
                />
              )
            }
            
          </Space>
        )
      }
    },

  ];
  
  return (
    <>
      <ChangeStatusModal
        openModal={isOpenStatusChangeModal}
        setOpenModel={setIsOpenStatusChangeModal}
        selectOrderId={selectOrderId}
        currentStatus={statusMapping[curStatus]}
      />
      <NotifyModal 
        isOpenNotifyModal={isOpenNotifyModal}
        setIsOpenNotifyModal={setIsOpenNotifyModal}
        selectOrderId={selectOrderId}
        curNotifyStatus={curNotifyStatus}
      />
      <ChangeResultModal
        openModal={isOpenChangeResultModal}
        setOpenModel={setIsOpenChangeResultModal}
        selectOrderId={selectOrderId}
        products={curProducts}
      />

      <Card bordered={false} style={{padding:'30px'}} >
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div style={{marginBottom: 20}}>
            <Button type="primary" htmlType="submit" style={{ boxShadow:'none', marginRight: 10}} icon={<ExportOutlined />}> 
              <span>Xuất mẫu</span>
            </Button>
            <Button type="primary" htmlType="submit" style={{ boxShadow:'none'}} icon={<PlusOutlined />} 
              onClick={()=>navigate('/create-test')}
            > 
              <span>Tạo mẫu</span>
            </Button>
          </div>
          <div style={{marginBottom: 20}}>
            <Select
              style={{margin: '0 5px'}}
              showSearch
              placeholder="Chọn trạng thái"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: 'NEW', label: 'Mới' },
                { value: 'WAITING_FOR_PAYMENT', label: 'Mẫu đạt - Chờ thanh toán' },
                { value: 'FAILED', label: 'Mẫu không đạt'},
                { value: 'PROCESSING', label: 'Đã thanh toán - Đang xử lý' },
                { value: 'REFUND', label: 'Hoàn trả' },
                { value: 'DELAY', label: 'Delay' },
                { value: 'COMPLETED', label: 'Đã hoàn thành' },
                { value: 'CANCELLED', label: 'Huỷ' },
              ]}
              onChange={(value) => {
                setStatus(value);
              }}
            />
            <Select
              showSearch
              style={{margin: '0 5px'}}
              placeholder="Đối tác "
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: 'Taman', label: 'Tâm An' },
                { value: 'Client', label: 'Khách hàng cá nhân' },
              ]}
              onChange={(value) => {
                setPartnerCode(value);
              }}
            />
            <Search 
              style={{margin: '0 10px', width: 250}} 
              placeholder="input search text"
              onChange={(e) => setTextSearch(e.target.value)}
            />
          </div>
        </div>

        <Table dataSource={sourceData} columns={columns} />
      </Card>
    </>
  );
};

export default TableContent;