import React, { useState, useCallback, useEffect } from 'react';
import { Button, Select, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import DoctorCreateModal from '../Doctor/DoctorCreateModal';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';

const { Item } = Form;

const DoctorInput = (isEditPage=false) => {
  const [listDoctor, setListDoctor] = useState([]);
  const [currentValue, setCurrentValue] = useState(undefined);

  const fetchDoctor = useCallback( async (keyword) => {
    try {
      const response = await fetchWithToken(`${backendUrl}/doctors?full_name=${encodeURIComponent(keyword)}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setListDoctor(data);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    fetchDoctor('');
  }, [fetchDoctor]);

  return (
    <div style={{display:'flex', alignItems: 'center', width:'100%', paddingRight:'20px'}}>
      <Form.Item
        label="Bác sĩ"
        name="doctor_id"
        style={{width:'100%'}}
      >
        <Select
          showSearch
          placeholder="Chọn bác sĩ"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={listDoctor.map(item => ({
            value: item.id,
            label: `${item.full_name} - ${item.phone_number}`,
            })
          )}
          onSearch={(value) => {
            fetchDoctor(value);
          }}
          value={currentValue}
          disabled={isEditPage===true}
        />
      </Form.Item>
    </div>
  );
};

export default DoctorInput;