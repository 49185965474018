import React, { useState, useCallback, useEffect } from 'react';
import { Select, Input, Form } from 'antd';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';

const { Item } = Form;

const PatientInput = (isEditPage) => {
  const [listPatient, setListPatient] = useState([]);

  const fetchPatient = useCallback( async (keyword) => {
    try {
      const response = await fetchWithToken(`${backendUrl}/patients?full_name=${encodeURIComponent(keyword)}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setListPatient(data);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    fetchPatient('');
  }, [fetchPatient]);

  return (
    <div style={{display:'flex', alignItems: 'center', width:'100%', paddingRight:'20px'}}>
      <Item
        label="Bệnh nhân"
        name="patient_id"
        style={{width:'100%'}}
      >
        <Select
          showSearch
          placeholder="Chọn bệnh nhân"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={listPatient.map(item => ({
            value: item.id,
            label: `${item.full_name} - ${item.phone_number}`,
            })
          )}
          disabled={isEditPage===true}
        />
      </Item>
    </div>
  );
};

export default PatientInput;