import React, { useEffect, useState } from 'react';
import { Modal, Input, Form, Row, Col, Radio, message } from 'antd';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';

const DoctorCreateModal = ({ openModal, onClose, onCreateDoctor, currentDoctor }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm(); // Create a form instance

  const handleCreateDoctor = async () => {
    setConfirmLoading(true);
    try {
      // Validate fields and get form values
      const values = await form.validateFields();
      // Send the form data to the API
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== null)
      );
      // if currentDoctor not exist add is_create = true to filteredValues
      if (!currentDoctor) {
        filteredValues.is_create = true;
      }
      
      // Send the filtered form data to the API
      const response = await fetchWithToken(`${backendUrl}/upsert_doctor/`, {
        method: 'POST',
        body: JSON.stringify(filteredValues), // Send cleaned values
      });

      if (!response.ok) {
        const errorData = await response.json(); // Await the JSON response
        onClose();
        throw new Error(JSON.stringify(errorData)); // Convert error data to a string for better debugging
      }
      const data = await response.json();
      onCreateDoctor(data); // Pass the created doctor data to the parent component
      message.success('Doctor created successfully!', 2);
      form.resetFields(); // Reset form fields on success
      setTimeout(() => {
        onClose();
        setConfirmLoading(false);
      }, 500);
    } catch (error) {
      message.error('Failed to create doctor: ' + error.message,2);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  // fill form with current doctor data if it exists
  useEffect(() => {
    form.setFieldsValue(currentDoctor);
  }, [currentDoctor]);
  
  return (
    <>
      <Modal
        title="Tạo bác sĩ"
        open={openModal}
        onOk={handleCreateDoctor}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={800}
      >
        <Form
          form={form}
          layout="vertical" // This layout option makes labels appear above the input fields
        >
          <Row>
            <Col span={12} style={{padding:10}}>
              <Form.Item
                label="Tên bác sĩ"
                name="full_name"
                rules={[{ required: true, message: 'Vui lòng nhập tên bác sĩ!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} style={{padding:10}}>
              <Form.Item
                label="số điện thoại"
                name="phone_number"
                rules={[{ required: true, message: 'Nhập số điện thoại' }]}
              >
                <Input type='number' />
              </Form.Item>
            </Col>
          </Row>
          
          <Row>
            <Col span={12} style={{padding:10}}>
              <Form.Item
                label="Email"
                name="email"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} style={{padding:10}}>
              <Form.Item
                label="Giới tính"
                name="gender"
              >
                <Radio.Group >
                  <Radio value={'1'}>Nam</Radio>
                  <Radio value={'2'}>Nữ</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12} style={{padding:10}}>
              <Form.Item
                label="Đơn vị công tác (Bệnh viện/trường)"
                name="hospital_name"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6} style={{padding:10}}>
              <Form.Item
                label="Khoa phòng/Bộ môn"
                name="department"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6} style={{padding:10}}>
              <Form.Item
                label="Chức vụ"
                name="position"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8} style={{padding:10}}>
              <Form.Item
                label="Mã số thuế"
                name="tax_code"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{padding:10}}>
              <Form.Item
                label="Số tài khoản"
                name="bank_account_number"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8} style={{padding:10}}>
              <Form.Item
                label="Ngân hàng"
                name="bank_name"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={6} style={{padding:10}}>
              <Form.Item
                label="Ngày tháng năm sinh"
                name="date_of_birth"
              >
                <Input type='date' />
              </Form.Item>
            </Col>
            <Col span={4} style={{padding:10}}>
              <Form.Item
                label="CCCD"
                name="national_id"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} style={{padding:10}}>
              <Form.Item
                label="Ngày cấp"
                name="date_of_issue"
              >
                <Input type='date'/>
              </Form.Item>
            </Col>
            <Col span={4} style={{padding:10}}>
              <Form.Item
                label="Hàm học vị"
                name="academic_rank_title"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6} style={{padding:10}}>
              <Form.Item
                label="Chuyên ngành"
                name="specialty"
              >
                <Input/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default DoctorCreateModal;