import React, { useEffect, useState, useCallback } from 'react';
import { Table, Form, Select, Input, Space, Typography, Row, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';

const formatNumber = (number) => {
  // round number
  number = Math.round(number);
  const formatter = new Intl.NumberFormat('de-DE'); // German locale
  return formatter.format(number);
};
const ListPriceInput = ({product_ids, partner_code, changeProductIds, form}) => {
  const [listPrice, setListPrice] = useState([]);
  const [listPriceOption, setListPriceOption] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [price, setPrice] = useState(0);
  const [priceAterTax, setPriceAfterTax] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);

  useEffect(() => {
      const newProductIds = sourceData.map(item => item.id);
      changeProductIds(newProductIds);
  }, [sourceData]);

  useEffect(() => {
    const totalProductPrice = sourceData
      .map(item => item.price ? parseFloat(item.price.replace(/[^0-9.-]+/g, '')):0)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    
    setPrice(totalProductPrice);
    const priceApplyDiscount = totalProductPrice - (totalProductPrice * discountValue / 100);
    setPriceAfterTax(Math.round(priceApplyDiscount + priceApplyDiscount*0.08));
    
    form.setFieldsValue({
      price_before_discount: priceApplyDiscount,
      total_amount: Math.round(priceApplyDiscount + priceApplyDiscount*0.08)
    });
  }, [sourceData, discountValue]);

  const handleDiscountChange = (e) => {
    const newDiscountValue = e.target.value ? parseFloat(e.target.value) : 0;
    setDiscountValue(newDiscountValue);
    
    const priceApplyDiscount = price - (price * newDiscountValue / 100);
    setPriceAfterTax(Math.round(priceApplyDiscount + priceApplyDiscount*0.08));
    
    form.setFieldsValue({
      total_amount: Math.round(priceApplyDiscount + priceApplyDiscount*0.08),
      price_before_discount: priceApplyDiscount
    });
  };

  const handlePriceChange = (e) => {
    const newPrice = e.target.value ? parseFloat(e.target.value) : 0;
    const priceAfterTax = Math.round(newPrice + newPrice*0.08);
    
    setPrice(newPrice);
    setPriceAfterTax(priceAfterTax);
    
    form.setFieldsValue({
      total_amount: priceAfterTax,
      price_before_discount: newPrice
    });
  };
  
  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1, // Incrementing index starting from 1
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'product_code',
      key: 'product_code',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'product_name',
      key: 'product_name',
      width: 200,
    },
    {
      title: 'Giá niêm yết',
      dataIndex: 'price',
      key: 'price',
      render: (text, record) => (
        <>{
          console.log('record', record)

        }
        {formatNumber(text)}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <DeleteOutlined style={{ color: 'red' }} onClick={() => {
            setSourceData(sourceData => sourceData.filter(item => item.id !== record.id));
          }} />
        </Space>
      ),
    },
  ];

  const fetchListPrice = useCallback( async (keyword, product_ids) => {
    try {
      const baseUrl = `${backendUrl}/products`;
      const url = new URL(baseUrl);
      const params = new URLSearchParams();
      if (keyword) params.append('product_name', keyword);
      if (partner_code) params.append('partner_code', partner_code);
      if (product_ids) params.append('ids', product_ids);
      url.search = params.toString();
      const response = await fetchWithToken(url.toString(), {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setListPrice(data);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, [partner_code]);


  const fetchInitSource = useCallback( async (product_ids) => {
    try {
      const baseUrl = `${backendUrl}/products`;
      const url = new URL(baseUrl);
      const params = new URLSearchParams();
      if (product_ids) params.append('ids', product_ids);
      url.search = params.toString();
      const response = await fetchWithToken(url.toString(), {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setSourceData(data);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }

  }, []);

  useEffect(() => {
    if(product_ids && product_ids.length > 0)
    {
      fetchInitSource(product_ids);
    }
}, [fetchInitSource, product_ids]);

  useEffect(() => {
    if (partner_code){
      fetchListPrice('');
    }
  }, [partner_code, fetchListPrice]);

  useEffect(() => {
  if (listPrice.length > 0) {

    const options = listPrice.filter(item => !sourceData.find(sourceItem => sourceItem.id === item.id)).map(item => ({
      value: item.id,
      label: `${item.product_code} - ${item.product_name}`,
    }));
    setListPriceOption(options);
  }
   
  }, [listPrice, sourceData]);

  
  return (
    <>
    <Row>
      <Col span={9}>
        <Form.Item 
          label="Sản phẩm"
          name="product"
        >
          <Select
            placeholder="Chọn sản phẩm"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={listPriceOption}
            onChange={(value) => {
              const newProduct = listPrice.find(item => item.id === value);
              if (sourceData.find(item => item.id === newProduct.id)) {
                return;
              }
              setSourceData([newProduct, ...sourceData]);
              form.setFieldsValue({total_amount: undefined});
            }}
            showSearch
            onSearch={(value) => {
              fetchListPrice(value);
              }
            }
          />
        </Form.Item>
      </Col>
      <Col span={3} style={{paddingRight:'20px', paddingLeft:'20px'}}>
        <Form.Item
          label="Giảm giá"
          name="discount_percent"
        >
          <Input
            type='number'
            placeholder="Discount (%)"
            onChange={handleDiscountChange}
          />
        </Form.Item>
      </Col>
      <Col span={6} style={{width:'100%'}}>
        <Form.Item
          label="Giá cuối"
          name="price_before_discount"
          style={{width:'100%'}}
        >
          <Input
            type='number'
            value={price}
            placeholder={formatNumber(price) + ' VND'}
            onChange={handlePriceChange}
          />
        </Form.Item>
      </Col>
      <Col span={6} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <Form.Item name="total_amount">
          <Input type='hidden'/>
        </Form.Item>
        <Typography.Text style={{marginRight: 20, fontSize:'16px'}} strong>Giá sau thuế: {formatNumber(priceAterTax)}</Typography.Text>
      </Col>
    </Row>
    <Table  scroll={{y: 300 }}  style={{overlay: 'auto !important'}} dataSource={sourceData} columns={columns} rowKey={(record) => record.id} />
    </>
  );
};

export default ListPriceInput;