import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, Modal, Form, Input, Typography, Card, message } from 'antd';
import 'antd/dist/reset.css'; // Ensure Ant Design styles are imported
import { fetchWithToken } from '../share/apiUtils';
import { backendUrl } from '../../constants';
import PatientCreateModal from './PatientCreateModal';

const { Title } = Typography;
const PatientPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [patient, setPatient] = useState([]);
  const [currentPatient, setCurrentPatient] = useState(null);

  const fetchPatient = useCallback( async () => {
      try {
        const response = await fetchWithToken(`${backendUrl}/patients`, {
          method: 'GET',
        });
    
        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
        }
    
        const data = await response.json();
        setPatient(data);
      } catch (error) {
        console.error('Error fetching options:', error);
        return [];
      }
    }, []);
  
    useEffect(() => {
      fetchPatient();
    }, [fetchPatient]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Full name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone number',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'address',
      dataIndex: 'province_city',
      key: 'province_city',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="primary" onClick={() => {
          setCurrentPatient(record);
          setOpenModal(true);
        }}>Edit</Button>
      ),
    }
  ];
  return (
    <div style={{width:'800px', height:'100vh'}}>
      <div>
        <Title level={4} style={{textAlign:'left'}}>Bệnh nhân</Title>
      </div>
      <Card bordered={false} style={{padding:0, textAlign:'right'}} >
        <Button type="primary" onClick={()=>{
          setOpenModal(true);
        }} style={{margin:'20px'}}>
          Add Patient
        </Button>
        <Table columns={columns} dataSource={patient} />
      </Card>

      
      {openModal && <PatientCreateModal 
        openModal={openModal} 
        onClose={()=>{
          setCurrentPatient(null);
          setOpenModal(false);
        }}
        onCreatePatient={() => {
          setCurrentPatient(null);
          fetchPatient();
        }}
        currentPatient={currentPatient}
      />}
    </div>
  );
};

export default PatientPage;
