import React, { useState, useEffect } from 'react';
import { Modal, message, Input, Form, Row, Col, Radio } from 'antd';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';

const PatientCreateModal = ({ openModal, onClose, onCreatePatient, currentPatient }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm(); // Create a form instance

  const handleCreatePatient = async () => {
    setConfirmLoading(true);
    try {
      // Validate fields and get form values
      const values = await form.validateFields();
      // Send the form data to the API
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== null)
      );
      // if currentDoctor not exist add is_create = true to filteredValues
      if (!currentPatient) {
        filteredValues.is_create = true;
      }

      // Send the form data to the API
      const response = await fetchWithToken(`${backendUrl}/upsert_patient/`, {
        method: 'POST',
        body: JSON.stringify(filteredValues), // Send form values as JSON
      });

      if (!response.ok) {
        const errorData = await response.json(); // Await the JSON response
        onClose();
        throw new Error(JSON.stringify(errorData)); // Convert error data to a string for better debugging
      }

      const data = await response.json();
      onCreatePatient(data); // Pass the created doctor data to the parent component
      message.success('Patient created successfully!', 2);
      form.resetFields(); // Reset form fields on success
      setTimeout(() => {
        onClose();
        setConfirmLoading(false);
      }, 500);
    } catch (error) {
      message.error('Failed to create doctor: ' + error.message, 2);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  // fill form with current doctor data if it exists
  useEffect(() => {
    form.setFieldsValue(currentPatient);
  }, [currentPatient]);
  
  return (
    <>
      <Modal
        title="Tạo bệnh nhân"
        open={openModal}
        onOk={handleCreatePatient}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={800}
      >
        <Form
          layout="vertical" // This layout option makes labels appear above the input fields
          form={form}
        >
          <Row>
            <Col span={12} style={{padding:10}}>
              <Form.Item
                label="Tên bệnh nhân"
                name="full_name"
                rules={[{ required: true, message: 'Vui lòng nhập tên bệnh nhân!' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} style={{padding:10}}>
              <Form.Item
                label="số điện thoại"
                name="phone_number"
                rules={[{ required: true, message: 'Nhập số điện thoại' }]}
              >
                <Input type='number' />
              </Form.Item>
            </Col>
          </Row>
          
          <Row>
            <Col span={12} style={{padding:10}}>
              <Form.Item
                label="Email"
                name="email"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} style={{padding:10}}>
              <Form.Item
                label="Giới tính"
                name="gender"
              >
                <Radio.Group >
                  <Radio value={1}>Nam</Radio>
                  <Radio value={2}>Nữ</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6} style={{padding:10}}>
              <Form.Item
                label="Ngày tháng năm sinh"
                name="date_of_birth"
              >
                <Input type='date' />
              </Form.Item>
            </Col>
            <Col span={6} style={{padding:10}}>
              <Form.Item
                label="CCCD"
                name="national_id"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} style={{padding:10}}>
              <Form.Item
                label="Địa chỉ"
                name="province_city"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PatientCreateModal;